<template>
  <p v-if="errorMessage">
    Connect tiktok failed: {{ errorMessage }}
  </p>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: this.$route.query.error || '',
    }
  },
}
</script>
